<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          V-Quality
        </h2>
      </b-link> -->
      <span class="brand-logo">
        <b-img v-if="dark != true" :src="appLogoImage" alt="logo" />
        <b-img v-else :src="appLogoImageDark" alt="logo" />
      </span>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            V-Quality Cliente
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text> -->

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email/Usuário" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Senha</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Esqueceu a senha?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Lembre-se de mim
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Entrar
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Esqueceu a senha? </span>
            <b-link :to="{ name: 'forgot-password' }">
              <span>&nbsp;Clique para recuperar</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <b-modal
      v-model="companyModal"
      centered
      title="Selecionar Empresa"
      hide-footer
      no-close-on-backdrop
      hide-header-close
    >
      <b-col>
        <v-select
          :options="companies"
          v-model="companySelected"
          :getOptionLabel="(item) => item.name"
          placeholder="Selecione..."
        ></v-select>
      </b-col>
      <b-col class="justify-content-end d-flex p-1">
        <b-button variant="danger" @click="cancelLogin">Cancelar</b-button>
        <b-button
          class="ml-1 variant-primary"
          variant="primary"
          :disabled="!companySelected ? true : false"
          @click="setCompany"
          >Confirmar</b-button
        >
      </b-col>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import ls from 'local-storage';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  setup() {
    // App Name
    const { appLogoImage, appLogoImageDark } = $themeConfig.app;
    return {
      appLogoImage,
      appLogoImageDark,
    };
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      dark: null,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v6.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      companyModal: false,
      companySelected: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dark = true;
        this.sideImg = require('@/assets/images/pages/login-v7.svg');

        return this.sideImg;
      }
      return this.sideImg;
    },
    company() {
      return this.$store.getters.company;
    },
    companies() {
      return this.$store.getters.companies;
    },

    user() {
      if (this.$store.getters.user) {
        return this.$store.getters.user;
      } else {
        return {
          name: '',
          email: '',
        };
      }
    },
  },

  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.login();
        }
      });
    },
    setCurrentCompany() {
      this.$store.commit('SET_CURRENT_COMPANY', this.selectedCompany);
      this.$router.push({ name: 'home' });
    },
    login() {
      this.$store
        .dispatch('login', { email: this.userEmail, password: this.password })
        .then(() => {
          if (!this.user.module_default && !this.user.module_training) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Você precisa ter ao menos um módulo vinculado ao seu usuário',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Login efetuado',
                icon: 'CheckIcon',
                variant: 'success',
              },
            });
          }

          if (!this.company) {
            this.companyModal = true;
          } else {
            if (this.user.module_default) {
              this.$router.push({ name: 'home' });
            } else {
              this.$router.push({ name: 'training-list' });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Login',
              text: `Erro: ${err}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },
    setCompany() {
      ls.set('company', this.companySelected);
      this.$store.commit('SET_COMPANY', this.companySelected);
      this.companyModal = false;

      this.$store.dispatch('getClientUser', {company_id: this.companySelected.id, user_id: this.user.id}).then((resp) => {
        this.$store.commit('SET_CLIENT_USER', resp);
      })

      if (this.user.module_default) {
        this.$router.push({ name: 'home' });
      } else {
        this.$router.push({ name: 'training-list' });
      }
    },
    cancelLogin() {
      this.$store.commit('UNSET_CLIENT_USER');
      this.$store.commit('UNSET_USER');
      ls.clear();
      this.companyModal = false;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
